import {
    TerminalsDataType, TerminalType
} from "./types";
import {Dispatch} from "redux";
import {setAppStatusAC} from "./appReducer";
import {personalAreaAPI} from "./axios.api";
import {handleServerNetworkError} from "../utils/error-utils";
import {prettifyAmount} from "../utils/utils";


type SetTerminalsACType = {
    type: 'SET-TERMINALS'
    terminals: TerminalType[]
}

type ActionsType = SetTerminalsACType

const initialState: TerminalsDataType = []

export const terminalsReducer = (state: TerminalsDataType = initialState, action: ActionsType): TerminalsDataType => {
    switch (action.type) {
        case 'SET-TERMINALS': {
            return [
                ...action.terminals
            ]
        }
        default: {
            return state
        }
    }
}
export const setTerminalsAC = (terminals: TerminalType[]): SetTerminalsACType => {
    return {type: 'SET-TERMINALS', terminals}
}
export const fetchTerminalsTC = (): any => {
    return (dispatch: Dispatch) => {
        // dispatch(setAppStatusAC('loading'))
        personalAreaAPI.getTerminals()
            .then(res => {
                const action = setTerminalsAC(res.data);
                dispatch(action);
                let bl = 0;
                const result = res.data.map(t => t.balances ? t.balances.map(b => b.currency === 'RUB' ||  b.currency === 'BRL' ? bl += b.amount : '') : '')
                localStorage.setItem('balanceTerminals', prettifyAmount(bl))
                // dispatch(setAppStatusAC('succeeded'));
            })
            .catch((err) => {
                handleServerNetworkError(err, dispatch)
            })
    }
}
